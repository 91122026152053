

import React, {useEffect, useState} from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Pagination,
  Paper,
  Box,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {
  fetchAppartments,
  selectAppartments,
  deleteAppartment,
  selectLoadingAppartmentList,
  getAppartmentDetails,
  getDataForCsv,
} from "./AppartmentListSlice";

import Backdrop from "components/form/Backdrop";
import ListCard from "components/ListCard";
import {useAnyDispatch, UseAppSelector} from "store/hooks";
import {useNavigate} from "react-router-dom";
import {saveCsv} from "utils/commonFunctions";
import {DataGrid} from "@mui/x-data-grid";

function GermanDateTimeCell({value}) {
  const date = new Date(value);
  return <>
    {date.toLocaleString("de-DE")}
  </>;
}


const steinelTranslation = [
  {value: "TemperatureCorrection", label: "Temperature Correction"},
  {value: "Presence1HoldTime", label: "Presence Hold Time"},
  {value: "TruePresenceSettingsScenario", label: "True Presence Settings Scenario"},
  {value: "Presence1", label: "Presence 1"},
  {value: "TruePresenceSettingsRadius", label: "True Presence Settings Radius"},
  {value: "Motion1COV", label: "Motion 1 COV"},
  {value: "TruePresence1", label: "True Presence 1"},
  {value: "Brightness1", label: "Brightness 1"},
  {value: "BluetoothEnabled", label: "Bluetooth Enabled"},
  {value: "TruePresenceSettingsHeight", label: "True Presence Settings Height"},
  {value: "DetectorFWVersion", label: "Detector Firmware Version"},
  {value: "HumidityCOV", label: "Humidity COV"},
  {value: "TruePresence1COV", label: "True Presence 1 COV"},
  {value: "Motion1", label: "Motion 1"},
  {value: "SensorName", label: "Sensor Name"},
  {value: "TemperatureCOV", label: "Temperature COV"},
  {value: "Presence1COV", label: "Presence 1 COV"},
  {value: "Humidity", label: "Humidity"},
  {value: "Temperature", label: "Temperature"},
  {value: "SensorSWVersion", label: "Sensor Software Version"},
  {value: "Brightness1COV", label: "Brightness 1 COV"},
  {value: "AerosolInfRiskTGA", label: "Aerosol Inf Risk TGA"},
  {value: "DewPoint", label: "Dew Point"},
  {value: "AerosolRiskOfInfectionStatus", label: "Aerosol Risk Of Infection Status"},
  {value: "SensorLEDPattern", label: "Sensor LED Pattern"},
  {value: "VOCCOV", label: "VOC COV"},
  {value: "CO2COV", label: "CO2 COV"},
  {value: "AerosolAirHyst", label: "Aerosol Air Hyst"},
  {value: "AerosolStaleAir", label: "Aerosol Stale Air"},
  {value: "BuzzerSetting", label: "Buzzer Setting"},
  {value: "AerosolAirTGA", label: "Aerosol Air TGA"},
  {value: "CO2TGA", label: "CO2 TGA"},
  {value: "AirPressureCOV", label: "Air Pressure COV"},
  {value: "Online", label: "Online"},
  {value: "NoiseCOV", label: "Noise COV"},
  {value: "AerosolRiskOfInfectionCOV", label: "Aerosol Risk Of Infection COV"},
  {value: "CO2Hyst", label: "CO2 Hyst"},
  {value: "DewPointCOV", label: "Dew Point COV"},
  {value: "CO2", label: "CO2"},
  {value: "Noise", label: "Noise"},
  {value: "AerosolRiskOfInfectionStatusCOV", label: "Aerosol Risk Of Infection Status COV"},
  {value: "AerosolStaleAirCOV", label: "Aerosol Stale Air COV"},
  {value: "ComfortZone", label: "Comfort Zone"},
  {value: "ComfortZoneUpperHum", label: "Comfort Zone Upper Hum"},
  {value: "AerosolStaleAirStatusCOV", label: "Aerosol Stale Air Status COV"},
  {value: "AerosolAirTAR", label: "Aerosol Air TAR"},
  {value: "AerosolInfRiskTAR", label: "Aerosol Inf Risk TAR"},
  {value: "LEDSetting", label: "LED Setting"},
  {value: "AirPressure", label: "Air Pressure"},
  {value: "AerosolStaleAir", label: "Aerosol Stale Air"},
  {value: "VOCCOV", label: "VOC COV"},
  {value: "CO2Hyst", label: "CO2 Hyst"},
  {value: "DewPoint", label: "Dew Point"},
  {value: "AerosolRiskOfInfectionStatus", label: "Aerosol Risk Of Infection Status"},
  {value: "DetectorFWVersion", label: "Detector Firmware Version"},
  {value: "AerosolRiskOfInfection", label: "Aerosol Risk Of Infection"},
  {value: "LEDSetting", label: "LED Setting"},
  {value: "CO2TAR", label: "CO2 TAR"},
  {value: "AerosolStaleAirStatus", label: "Aerosol Stale Air Status"},
  {value: "Brightness1", label: "Brightness 1"},
  {value: "AerosolAirTAR", label: "Aerosol Air TAR"},
  {value: "Presence1HoldTime", label: "Presence Hold Time"},
  {value: "ComfortZoneUpperTemp", label: "Uppper comfort zone for indoor"},
  {value: "ComfortZoneLowerHum", label: "Lower comfort zone for indoor"},

];
function getValueLabel(value) {
  const sensor = steinelTranslation.find((sensor) => sensor.value === value);
  return sensor ? sensor.label : value;
}


export function AppartmentsList() {
  const {t} = useTranslation();
  const dispatch=useAnyDispatch();
  const navigate=useNavigate();
  const [page, setPage]=useState(0);
  const [total, setTotal]=useState(0);

  const appartments=UseAppSelector(selectAppartments);
  const loading =UseAppSelector(selectLoadingAppartmentList);

  useEffect(()=>{
    dispatch(fetchAppartments(page))
        .then((res)=>{
          setTotal(res.payload.total);
        });
  }, [page]);

  const editClickHandler=(id:number)=>{
    navigate(`/editAppartment/${id}`, {replace: false});
  };

  const deleteClickHandler=(id:number)=>{
    dispatch(deleteAppartment(id));
  };


  const saveAsCsvHandler=(id:number)=>{
    dispatch(getDataForCsv(id))
        .then((res)=> {
          saveCsv(res.payload.result);
        });
  };


  const columns = [
    {field: "clientid", headerName: t("sensor.clientid"), width: 100},
    {field: "roomName", headerName: t("sensor.roomName"), width: 180},
    {field: "attribute", headerName: t("sensor.attribut"), width: 300, renderCell: (params) => getValueLabel(params.value)},
    {field: "value", headerName: t("sensor.value"), width: 200},
    {field: "timestamp", headerName: t("sensor.timestamp"), width: 200, renderCell: (params) => <GermanDateTimeCell value={params.value} />},

  ];

  const ClientDataGrid = (sensorData) => {
    useEffect(()=>{
      console.log("ClientDataGrid", JSON.stringify(sensorData?.sensorData));
    }, []);

    return (
      <div style={{height: 400, width: "99%"}}>
        <Typography sx={{fontWeight: "bold"}}>{t("appartment.info.sensordata")}</Typography>
        <DataGrid
          rows={sensorData?.sensorData || []}
          columns={columns}
          checkboxSelection={false}
        />
      </div>
    );
  };


  const RoomDetails = (appartment) => {
    useEffect(()=>{
      console.log("RoomDetails", JSON.stringify(appartment));
    }, []);
    return (
      <Paper style={{width: "99%"}} >
        <Grid item sx={{m: 2}}>
          <Typography sx={{fontWeight: "bold"}}>{t("appartment.info")}</Typography>
          {appartment?.appartment?.appartment_details?.rooms && appartment?.appartment?.appartment_details.rooms.map((room) => (
            <>
              <Typography fontSize='14px'>{`${room.name ?? t("not.defined")}`}</Typography>
              {room.sensors && room.sensors.map((sensor) => (
                <>
                  <div>
                    { sensor.sensor_type ?
                    <Typography paddingLeft='10px' fontSize='14px'>{`${sensor.name ? `${sensor.name}` : ""} (${t("sensor.type")} ${sensor.sensor_type ?? t("not.defined")})`}</Typography> :
                    <Typography paddingLeft='10px' fontSize='14px'>{t("not.defined")}</Typography>

                    }
                  </div>
                </>
              ))}
            </>
          ))}
        </Grid>
      </Paper>
    );
  };


  return (
    <Grid container justifyContent={"space-around"}>
      <Backdrop open={Object.values(loading)?.includes("pending")}/>
      <Grid item xs={12} marginBottom={3}>
        <Card variant="outlined">
          <CardContent>
            <Grid item xs={12}>
              <Typography variant="h5">{t("appartment.list")}</Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item container spacing={1}>
        {appartments && appartments.map((appartment, index)=> <Grid key={index} item xs={12}>
          <ListCard
            subtitle={`${appartment.country} ${appartment.street}`}
            title={appartment.name}
            page={page}
            dataCyId={appartment.appartment_id}
            getCollapseDetails={()=>dispatch(getAppartmentDetails(appartment.appartment_id))}
            body={<></>}
            CollapseContent={
              <div>
                <RoomDetails appartment={appartment} />
                <ClientDataGrid sensorData={appartment?.appartment_details?.sensorData}/>
              </div>
            }
            actionDropdown={[{name: "Delete", datacy: "deleteAppartment_"+ appartment.appartment_id, action: ()=>deleteClickHandler(appartment.appartment_id)},
              {name: "Edit", datacy: "editAppartment_"+ appartment.appartment_id, action: ()=>editClickHandler(appartment.appartment_id)},
              {name: "Save as CSV", action: ()=>saveAsCsvHandler(appartment.appartment_id)}]}/>
        </Grid>)}
      </Grid>
      <Grid item marginTop={2} >
        <Card>
          <Pagination
            onChange={(event, newPage)=>setPage(newPage-1)}
            count={Math.ceil(total/5)}
            showFirstButton
            showLastButton
          />
        </Card>
      </Grid>
    </Grid>
  );
}
