import React, {ReactEventHandler} from "react";
import {
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";


type RadioButtonGroupProps={
  options:any[],
  value:any,
  dataCy:string,
  onChange:ReactEventHandler,
    label:string,
    error:boolean,
}
import i18n from "utils/i18n";

export default ({
  onChange,
  value,
  error,
  dataCy,
  label,
  options,
}:RadioButtonGroupProps) =>
  <>
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        value={value}
        onChange={(event:any)=>onChange(event?.target.value)}
      >
        {options.map((option:any)=>
          <FormControlLabel data-cy={`${dataCy}_${option}`} key={option} value={option} control={<Radio />} label={`${i18n.t(option)}`}/>)}

      </RadioGroup>
    </FormControl>
  </>;

