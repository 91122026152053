export const fieldsInfo={
  "first_name": {
    page: "patient",
    initialValue: "",
    validate: ()=>false,
  },
  "last_name": {
    page: "patient",
    initialValue: "",
    validate: ()=>false,
  },
  "date_of_birth": {
    page: "patient",
    initialValue: null,
    validate: ()=>false,
  },
  "gender": {
    page: "patient",
    initialValue: "",
    validate: ()=>false,
  },
  "street": {
    page: "patient",
    initialValue: "",
    validate: ()=>false,
  },
  "post_code": {
    page: "patient",
    initialValue: "",
    validate: ()=>false,
  },
  "city": {
    page: "patient",
    initialValue: "",
    validate: ()=>false,
  },
  "country": {
    page: "patient",
    initialValue: "",
    validate: ()=>false,
  },
  "email": {
    page: "patient",
    initialValue: "",
    validate: ()=>false,
  },
  "phone": {
    page: "patient",
    initialValue: "",
    validate: ()=>false,
  },
  "preferred_contact_method": {
    page: "patient",
    initialValue: "",
    validate: ()=>false,
  },
  "insurance": {
    page: "patient",
    initialValue: "",
    validate: ()=>false,
  },
  "insurance_gln": {
    page: "patient",
    initialValue: "",
    validate: ()=>false,
  },

  "locomotion_help": {
    page: "medicalInformation",
    initialValue: "",
    validate: ()=>false,
  },
  "tools_comments": {
    page: "medicalInformation",
    initialValue: "",
    validate: ()=>false,
  },
  "test_leaving_reason": {
    page: "medicalInformation",
    initialValue: "",
    validate: ()=>false,
  },
  "allergies": {
    page: "medicalInformation",
    initialValue: "",
    validate: ()=>false,
  },
  "diagnosis": {
    page: "medicalInformation",
    initialValue: "",
    validate: ()=>false,
  },
  "body_functions_damage": {
    page: "medicalInformation",
    initialValue: "",
    validate: ()=>false,
  },
  "body_structure_damage": {
    page: "medicalInformation",
    initialValue: "",
    validate: ()=>false,
  },
  "participation_impairment": {
    page: "medicalInformation",
    initialValue: "",
    validate: ()=>false,
  },
  "challenge_factor": {
    page: "medicalInformation",
    initialValue: "",
    validate: ()=>false,
  },
  "presences": {
    page: "presences",
    initialValue: null,
    validate: ()=>false,
  },
  "check_in_date": {
    page: "booking",
    initialValue: new Date(),
    validate: ()=>false,
  },
  "check_list": {
    page: "booking",
    initialValue: "",
    validate: ()=>false,
  },
  "information_sheet": {
    page: "booking",
    initialValue: "",
    validate: ()=>false,
  },
  "selection_criteria": {
    page: "booking",
    initialValue: "",
    validate: ()=>false,
  },
  "risk_acceptance": {
    page: "booking",
    initialValue: "",
    validate: ()=>false,
  },
  "check_out_date": {
    page: "booking",
    initialValue: new Date(),
    validate: ()=>false,
  },
  "appartment_id": {
    page: "booking",
    initialValue: "",
    validate: ()=>false,
  },
  "booking_status_admission": {
    page: "booking",
    initialValue: false,
    validate: ()=>false,
  },
  "booking_status_visit": {
    page: "booking",
    initialValue: false,
    validate: ()=>false,
  },
  "booking_status_follow_up": {
    page: "booking",
    initialValue: false,
    validate: ()=>false,
  },
} as
  {[key:string]:{
    page:string
    initialValue:string | Date | boolean | null
    validate: (value:any)=>boolean
  }};

