import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from "i18next-http-backend";

let navigatorlang = navigator.language;
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
const loadPath = REACT_APP_API_URL + "nexus/{{lng}}";

try {
  navigatorlang = navigatorlang.substring(0, 2);
  if (navigatorlang === "de" || navigatorlang === "fr") {
    if (navigatorlang === "de") {
      navigatorlang = "DE";
    }
    if (navigatorlang === "fr") {
      navigatorlang = "FR";
    }
  } else {
    navigatorlang = "DE";
  }
} catch (ex) {
  console.error("[I18n]", ex);
}

i18n
    .use(initReactI18next)
    .use(Backend)
    .init({
      cleanCode: true,
      lng: navigatorlang,
      fallbackLng: "de",
      debug: true,
      ns: ["Standard"],
      defaultNS: "Standard",
      keySeparator: false,
      interpolation: {
        escapeValue: false,
        formatSeparator: ",",
      },
      react: {
        // withRef: false,
        bindI18n: "languageChanged loaded",
        // bindStore: "added removed",
        nsMode: "default",
      },
      supportedLngs: ["de", "fr"],
      backend: {
        loadPath: loadPath,
      },
    },
    () => {
      /* callback method */
    },
    );

export default i18n;

export const i18_instance = {
  i18n: i18n,
  language: i18n.language,
};
