import React, {useState} from "react";
import {
  Grid,
  Collapse,
  IconButton,
  Typography,

} from "@mui/material";
import {ExpandMore} from "@mui/icons-material";
import i18n from "utils/i18n";


export default ({
  children,
}) =>{
  const [expanded, setExpanded]=useState(false);

  const handleExpandClick=() => {
    setExpanded(!expanded);
  };


  const dottedSeparator={
    border: "none",
    marginTop: "initial",
    marginBottom: "initial",
    borderTop: "1px dotted grey",
    width: "100%",
  };

  const background={
    "backgroundColor": "#f0f3f5",
    "padding": "6px",
  };
  return <>
    <Grid item xs={12} flexDirection='row' display='flex' alignItems='center'>
      <Grid item >
        <Typography>{`${i18n.t("bookings")}`}</Typography>
        <IconButton
          id="expandButton"
          sx={{transform: expanded?"rotate(180deg)" :"rotate(0deg)"}}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          style={{paddingLeft: "0"}}
        >
          <ExpandMore />
        </IconButton>
      </Grid>
    </Grid>
    <Collapse in={expanded} timeout="auto" unmountOnExit style={{...dottedSeparator, ...background}}>
      {children}
    </Collapse>
  </>;
};

