export const fieldsInfo={
  room_id: {
    page: "sensor",
    initialValue: "",
    validate: ()=>false,
  },
  hub_id: {
    page: "sensor",
    initialValue: 0,
    validate: ()=>false,
  },
  sensor_type: {
    page: "sensor",
    initialValue: "",
    validate: ()=>false,
  },
  serial_number: {
    page: "sensor",
    initialValue: "",
    validate: ()=>false,
  },
  hub_name: {
    page: "sensor",
    initialValue: "",
    validate: ()=>false,
  },
  configuration: {
    page: "sensor",
    initialValue: "",
    validate: ()=>false,
  },
  access_token: {
    page: "sensor",
    initialValue: "",
    validate: ()=>false,
  },
  name: {
    page: "sensor",
    initialValue: "",
    validate: ()=>false,
  },
  client_id: {
    page: "sensor",
    initialValue: "",
    validate: ()=>false,
  },
  password: {
    page: "sensor",
    initialValue: "",
    validate: ()=>false,
  },
  topic: {
    page: "sensor",
    initialValue: "",
    validate: ()=>false,
  },
  refresh_token: {
    page: "sensor",
    initialValue: "",
    validate: ()=>false,
  },
  application_key: {
    page: "sensor",
    initialValue: "",
    validate: ()=>false,
  },
  client_secret: {
    page: "sensor",
    initialValue: "",
    validate: ()=>false,
  },
} as
    {[key:string]:{
      page:string
      initialValue:string | Date | boolean | number | null
      validate: (value:any)=>boolean
    }};


