import {createSlice, PayloadAction, createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "store/store";
import {fieldsInfo} from "./utils";
import {post, get} from "../../utils/api";
import {displayToastError, displayToastSuccess} from "features/ErrorBoundary/errorBoundarySlice";

const mapServerSensor = (sensor: any) => {
  const serverSensor: any = {
    sensor_id: sensor.sensor_id,
    sensor_type: sensor.sensor_type,
    room_id: sensor.room_id,
    serial_number: sensor.serial_number,
    name: sensor.name,
  };

  if (sensor.hub_id !== undefined) {
    serverSensor.hub = {
      hub_id: sensor.hub_id,
      sensor_type: sensor.sensor_type,
      name: sensor.hub_name,
      configuration: {
        access_token: sensor.access_token,
        refresh_token: sensor.refresh_token,
        application_key: sensor.application_key,
        client_id: sensor.client_id,
        client_secret: sensor.client_secret,
        password: sensor.password,
        topic: sensor.topic,
      }};
  }
  return serverSensor;
};


const mapClientSensor = (sensor: any) => {
  const clientSensor: any = sensor;

  if (sensor.hub) {
    clientSensor.hub_name = sensor.hub.name;
    if (sensor.hub.configuration) {
      clientSensor.access_token = sensor.hub.configuration.access_token;
      clientSensor.refresh_token = sensor.hub.configuration.refresh_token;
      clientSensor.application_key = sensor.hub.configuration.application_key;
      clientSensor.client_id = sensor.hub.configuration.client_id;
      clientSensor.client_secret = sensor.hub.configuration.client_secret;
      clientSensor.password = sensor.hub.configuration.password;
      clientSensor.topic = sensor.hub.configuration.topic;
    } else {
      clientSensor.access_token = "";
      clientSensor.refresh_token = "";
      clientSensor.application_key = "";
      clientSensor.client_id = "";
      clientSensor.client_secret = "";
      clientSensor.password = "";
      clientSensor.topic = "";
    }
  } else {
    clientSensor.name = "";
    clientSensor.access_token = "";
    clientSensor.refresh_token = "";
    clientSensor.application_key = "";
    clientSensor.client_id = "";
    clientSensor.password = "";
    clientSensor.topic = "";
  }
  return clientSensor;
};

export const postSensor = createAsyncThunk(
    "sensorForm/postSensor",
    async (sensor:any, {rejectWithValue, dispatch}) => {
      try {
        const response = await post("home4/sensors", mapServerSensor(sensor));
        console.debug("sensor response", response);
        if (response===undefined||response.success==false) {
          dispatch(displayToastError("POST.SENSOR.FAILED"));
          throw new Error("Cannot post sensor");
        } else {
          dispatch(displayToastSuccess("POST.SENSOR.SUCCESS"));
          console.log("response", response);
          return response;
        }
      } catch (error) {
        console.error("error", error);
        dispatch(displayToastError("POST.SENSOR.FAILED"));
        throw new Error("Cannot post sensor");
      }
    },
);
export const editSensor = createAsyncThunk(
    "sensorForm/editSensor",
    async ({sensor_id, sensorEditData}:{sensor_id:string, sensorEditData:any}, {dispatch, rejectWithValue}) => {
      const response = await post("home4/sensors/" + sensor_id, mapServerSensor(sensorEditData));
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("EDIT.SENSOR.FAILED"));
        return rejectWithValue("Cannot EDIT sensor");
      } else {
        dispatch(displayToastSuccess("EDIT.SENSOR.SUCCESS"));
        return response;
      }
    },
);
export const getSensor = createAsyncThunk(
    "sensorForm/getSensor",
    async (sensor_id:string, {rejectWithValue, dispatch}) => {
      const response = await get("home4/sensors/" + sensor_id);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("GET.SENSOR.FAILED"));
        return rejectWithValue("Cannot GET sensor");
      } else {
        dispatch(displayToastSuccess("GET.SENSOR.SUCCESS"));
        return response;
      }
    },
);

export const getHub = createAsyncThunk(
    "sensorForm/getHub",
    async (hub_id:any, {rejectWithValue, dispatch}) => {
      const response = await get("home4/hubs/"+ hub_id);
      if (response===undefined||response.success==false) {
        // dispatch(displayToastError("GET.HUBS.FAILED"));
        return rejectWithValue("Cannot GET hub");
      } else {
        // dispatch(displayToastSuccess("GET.HUBS.SUCCESS"));
        return response;
      }
    },
);

export const getHubs = createAsyncThunk(
    "sensorForm/getHubs",
    async (sensor_type:any, {rejectWithValue, dispatch}) => {
      const response = await get("home4/hubs/"+ sensor_type);
      if (response===undefined||response.success==false) {
        dispatch(displayToastError("GET.HUBS.FAILED"));
        return rejectWithValue("Cannot GET hub");
      } else {
        dispatch(displayToastSuccess("GET.HUBS.SUCCESS"));
        return response;
      }
    },
);

export interface sensorFormState {
 sensorFields: {
    [key:string]:string | number | boolean | Date
  },
  hubs:{
      hub_id?: any;
      serial_number:string
      sensor_type:string
      name:string
      configuration:{
        client_id: string;
        password: string;
        topic: string;
        access_token: string;
        refresh_token: string;
        application_key: string;
        client_secret: string;
      }
      deleted?:boolean
  }[],
  error: {[key:string]:boolean},
  loading: {
    postSensor:string,
    editSensor:string,
    getSensor:string,
    getHubs:string,
  },
}

const initialState: sensorFormState = {
  sensorFields: Object
      .keys(fieldsInfo)
      .filter((e)=>fieldsInfo[e].page==="sensor")
      .reduce((a, e)=>({...a, [e]: fieldsInfo[e].initialValue}), {}),
  error: Object
      .keys(fieldsInfo)
      .reduce((a, e)=>({...a, [e]: false}), {}),
  hubs: [],
  loading: {
    postSensor: "idle",
    editSensor: "idle",
    getSensor: "idle",
    getHubs: "idle",
  },

};


export const sensorFormSlice = createSlice({
  name: "sensorForm",
  initialState,
  reducers: {
    clearSensorFormFields: (state)=>{
      state.sensorFields=initialState.sensorFields;
    },
    changeSensorFieldValue: (state, action: PayloadAction<{field:string, value:any}>) => {
      state.sensorFields[action.payload.field] = action.payload.value;
      state.error[action.payload.field] = fieldsInfo[action.payload.field].validate(action.payload.value);
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(postSensor.pending, (state, action) => {
          if (state.loading.postSensor === "idle") {
            state.loading.postSensor = "pending";
          }
        })
        .addCase(postSensor.fulfilled, (state, action) => {
          if (
            state.loading.postSensor === "pending"
          ) {
            state.loading.postSensor = "idle";
          }
        })
        .addCase(postSensor.rejected, (state, action) => {
          if (
            state.loading.postSensor === "pending"
          ) {
            state.loading.postSensor = "idle";
          }
        })
        .addCase(editSensor.pending, (state, action) => {
          if (state.loading.editSensor === "idle") {
            state.loading.editSensor= "pending";
          }
          console.log("idle", action);
        })
        .addCase(editSensor.fulfilled, (state, action) => {
          if (
            state.loading.editSensor === "pending"
          ) {
            state.loading.editSensor = "idle";
          }
        })
        .addCase(editSensor.rejected, (state, action) => {
          if (
            state.loading.editSensor === "pending"
          ) {
            state.loading.editSensor = "idle";
          }
        })
        .addCase(getSensor.pending, (state, action) => {
          if (state.loading.getSensor === "idle") {
            state.loading.getSensor = "pending";
          }
        })
        .addCase(getSensor.fulfilled, (state, action) => {
          if (
            state.loading.getSensor === "pending"
          ) {
            state.sensorFields=mapClientSensor(action.payload.result);
            state.sensorFields.room_id=action.payload.result.room.room_id;
            state.loading.getSensor = "idle";
          }
        })
        .addCase(getSensor.rejected, (state, action) => {
          if (
            state.loading.getSensor === "pending"
          ) {
            state.loading.getSensor = "idle";
          }
        })
        .addCase(getHubs.pending, (state, action) => {
          if (state.loading.getHubs === "idle") {
            state.loading.getHubs = "pending";
            state.hubs = [];
          }
        })
        .addCase(getHubs.fulfilled, (state, action) => {
          if (
            state.loading.getHubs === "pending"
          ) {
            state.loading.getHubs = "idle";
            state.hubs = action.payload.result;
          }
        })
        .addCase(getHubs.rejected, (state, action) => {
          if (
            state.loading.getHubs === "pending"
          ) {
            state.loading.getHubs = "idle";
            state.hubs = [];
          }
        });
  },

});

export const {
  clearSensorFormFields,
  changeSensorFieldValue,
} = sensorFormSlice.actions;

export const selectSensorFields = (state: RootState) => state.sensorForm.sensorFields;
export const selectErrorFields = (state: RootState) => state.sensorForm.error;
export const selectLoadingSensorForm = (state: RootState) => state.sensorForm.loading;


export default sensorFormSlice.reducer;

