

import React, {useEffect, useState} from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Pagination,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {UseAppSelector, useAnyDispatch} from "store/hooks";

import ListCard from "components/ListCard";
import {
  selectBookings,
  fetchBookings,
  selectLoadingBookingList,
  deleteBooking,
} from "./bookingListSlice";
import {formatDateDisplay} from "utils/formatting";
import {useNavigate} from "react-router-dom";
import Backdrop from "components/form/Backdrop";

export function BookingsList() {
  const {t} = useTranslation();
  const dispatch=useAnyDispatch();
  const bookings=UseAppSelector(selectBookings);
  const loading=UseAppSelector(selectLoadingBookingList);
  const [page, setPage] = useState(0);
  const [total, setTotal]=useState(0);
  const navigate=useNavigate();

  useEffect(()=>{
    dispatch(fetchBookings(page))
        .then((res)=>{
          setTotal(res.payload.total);
        });
  }, [page]);

  const editClickHandler=(id:number)=>{
    navigate(`/editPatient/${id}`, {replace: false});
  };

  const deleteClickHandler=(id:number)=>{
    dispatch(deleteBooking(id));
  };

  const formatStatus=(...statii)=>{
    return statii.filter((status) => status).join(", ");
  };

  return (
    <Grid container justifyContent={"space-around"} >
      <Backdrop open={Object.values(loading).includes("pending")}/>
      <Grid item xs={12} marginBottom={3}>
        <Card variant="outlined">
          <CardContent>
            <Grid item xs={12}>
              <Typography variant="h5">{t("bookings.list")}</Typography>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item container spacing={1}>
        {bookings&&bookings.map((booking, index)=>
          <Grid item xs={12} key={index}>
            <ListCard
              dataCyId={booking.booking_id}
              subtitle={`${t("appartment.list.checkin")}: ${formatDateDisplay(booking.check_in_date)}-${t("appartment.list.checkout")}: ${formatDateDisplay(booking.check_out_date)}`}
              title={`${booking.patient.first_name} ${booking.patient.last_name}`}
              body={<>
                {(booking.booking_status_admission||booking.booking_status_follow_up||booking.booking_status_visit)&&
                <Typography paddingLeft='10px'>{t("Status")}: {formatStatus(booking.booking_status_admission&&t("addmision.data"), booking.booking_status_follow_up&&t("followup.survey"), booking.booking_status_visit&&t("visit.information"))}</Typography>}
                {booking.appartment?.name&&<Typography paddingLeft='10px'>{booking.appartment?.name}</Typography>}
              </>}
              page={page}
              CollapseContent={<>
                <Typography fontSize='14px'>{`${t("Patient-ID")}: ${booking.patient.patient_id}`}</Typography>
                <Typography fontSize='14px'>{`${t("Diagnose")}: ${booking.medical_information.diagnosis?.code??""} ${booking.medical_information.diagnosis?.description??"-"}`}</Typography>
              </>}
              actionDropdown={[{name: "Delete", datacy: "deleteBooking_"+ booking.booking_id, action: ()=>deleteClickHandler(booking.booking_id)}, {name: " Edit", datacy: "editBooking_"+ booking.booking_id, action: ()=>editClickHandler(booking.booking_id)}]}/>
          </Grid>)}
      </Grid>
      <Grid item marginTop={2} >
        <Card>
          <Pagination
            onChange={(event, newPage)=>setPage(newPage-1)}
            count={Math.ceil(total/5)}
            showFirstButton
            showLastButton
          />
        </Card>
      </Grid>
    </Grid>
  );
}
