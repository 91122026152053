import React from "react";
import {Box, Container} from "@mui/material";
import {Header} from "./Header";
import {Content} from "./Content";
import {Footer} from "./Footer";
import ErrorBoundary from "../features/ErrorBoundary/ErrorBoundary"; // -- npm run build complains

export const Layout= ()=>(
  <Box height="100vh" display="flex" flexDirection="column">
    <Box> <Header/></Box>
    <Box flex={1} overflow="overlay">
      <Container maxWidth="lg" sx={{padding: 1}}>
        <ErrorBoundary/>
        <Content/>
      </Container>
    </Box>
    <Box><Footer/></Box>
  </Box>
);
