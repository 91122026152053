import React, {useEffect, useState} from "react";
import {
  Grid,
  Stepper,
  Step,
  StepLabel,
  Card,
  TextField,
  Toolbar,
  Button,
  CardHeader,
  Avatar,
  CardActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Backdrop from "components/form/Backdrop";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {UseAppSelector, UseAppDispatch} from "store/hooks";
import {
  changeRoomNameValue,
  changeResidenceFieldValue,
  selectResidenceFields,
  selectRoomData,
  changeEquipmentValue,
  addRoom,
  addEquipmentToRoom,
  postAppartment,
  clearAppartmentFormFields,
  removeRoom,
  removeEquipment,
  selectErrorField,
  getAppartment,
  editAppartment,
  selectLoadingAppartmentForm,
  changeRoomTypeValue,
} from "./apartmentFormSlice";
import {FaTrash} from "utils/icons";
import CountryDropdown from "components/form/CountryDropdown";
import HomeIcon from "@mui/icons-material/Home";

export function ApartmentForm() {
  const {t} = useTranslation();
  const dispatch = UseAppDispatch();
  const navigate=useNavigate();
  const {id}=useParams();

  const residenceFieldsStoreValues = UseAppSelector(selectResidenceFields);
  const roomData = UseAppSelector(selectRoomData);
  const errorData = UseAppSelector(selectErrorField);
  const loading = UseAppSelector(selectLoadingAppartmentForm);

  useEffect(()=>{
    dispatch(clearAppartmentFormFields());
    if (id) {
      dispatch(getAppartment(id));
    }
  }, []);

  const handlePostAppartment=async ()=>{
    const rooms=roomData.roomFields.map((room)=>{
      if (id) {
        return {room_id: room.room_id, name: room.name, deleted: room.deleted, equipment: room.equipment, room_type: room.room_type};
      }
      return {name: room.name, equipment: room.equipment, room_type: room.room_type};
    });


    const appartment:any= {...residenceFieldsStoreValues, rooms: rooms};
    id?dispatch(editAppartment({appartment_id: id, appartmentEditData: appartment}))
        .then(()=>{
          navigate("/apartments", {replace: true});
        }):
    dispatch(postAppartment(appartment))
        .then(()=>{
          navigate("/apartments", {replace: true});
        });
  };
  // Stepper Variables
  const [stepperPage, setStepperPage]=useState(0);
  const steps=["residence", "room"]
      .map((e, idx)=>({
        key: t(`entry.step.${e}`),
        title: window.innerWidth>
        768|| stepperPage===idx? t(`entry.step.${e}`):"",
        onClick: () => setStepperPage(idx),
      }));

  const displayProp=(pageNumber:typeof stepperPage)=>({display: stepperPage===pageNumber?"flex" as "flex":"none" as "none"});

  return (
    <Card sx={{width: "100%", padding: 1}}>
      <Backdrop open={Object.values(loading)?.includes("pending")}/>
      <Grid container>
        <Grid item xs={12}>
          <Stepper nonLinear activeStep={stepperPage} alternativeLabel>
            {steps.map((e, index) =><Step key={e.key} onClick={e.onClick}><StepLabel >{e.title}</StepLabel></Step>)}
          </Stepper>
          <Grid item xs={12} container {...displayProp(0)}>
            <Card sx={{width: "100%", padding: 3}}>
              <CardHeader
                className="p-0 pb-3"
                avatar={
                  <Avatar aria-label="recipe" style={{
                    color: "#63c2de",
                    backgroundColor: "#f0f3f5",
                  }}>
                    <HomeIcon data-toggle="popover" data-content="Disabled popover" />
                  </Avatar>
                }
                title={t("residence.title")}
              />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    onChange={(event)=>dispatch(changeResidenceFieldValue({field: "name", value: event?.target.value}))}
                    value={residenceFieldsStoreValues.name}
                    error={errorData.name}
                    label= {t("residence.name")}
                    inputProps={{"data-cy": "name"}}
                    variant="standard" />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    onChange={(event)=>dispatch(changeResidenceFieldValue({field: "street", value: event?.target.value}))}
                    value={residenceFieldsStoreValues.street}
                    inputProps={{"data-cy": "streetNr"}}
                    label= {t("residence.streetNr")}
                    variant="standard" />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    onChange={(event)=>dispatch(changeResidenceFieldValue({field: "post_code", value: event?.target.value}))}
                    value={residenceFieldsStoreValues.post_code}
                    error={errorData.post_code}
                    label= {t("residence.zipCode")}
                    inputProps={{"data-cy": "zipCode"}}
                    variant="standard" />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    onChange={(event)=>dispatch(changeResidenceFieldValue({field: "location", value: event?.target.value}))}
                    value={residenceFieldsStoreValues.location}
                    error={errorData.location}
                    label= {t("residence.location")}
                    inputProps={{"data-cy": "location"}}
                    variant="standard" />
                </Grid>
                <Grid item xs={3}>
                  <CountryDropdown
                    onChange={(event:React.SyntheticEvent)=>{
                      console.log(event);
                      dispatch(changeResidenceFieldValue({field: "country", value: event}));
                    }
                    }
                    label= {t("residence.country")}
                    error={errorData.country}
                    value={String(residenceFieldsStoreValues.country)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    onChange={(event)=>dispatch(changeResidenceFieldValue({field: "floor", value: event?.target.value}))}
                    value={residenceFieldsStoreValues.floor}
                    error={errorData.floor}
                    label= {t("residence.floor")}
                    inputProps={{"data-cy": "floor"}}

                    variant="standard" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    onChange={(event)=>dispatch(changeResidenceFieldValue({field: "information_for_doctors", value: event?.target.value}))}
                    value={residenceFieldsStoreValues.information_for_doctors}
                    error={errorData.information_for_doctors}
                    label= {t("residence.informationForDoctors")}
                    inputProps={{"data-cy": "informationForDoctors"}}
                    variant="standard" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    onChange={(event)=>dispatch(changeResidenceFieldValue({field: "contactmail", value: event?.target.value}))}
                    value={residenceFieldsStoreValues.contactmail}
                    error={errorData.contactmail}
                    label= {t("residence.contactmail")}
                    inputProps={{"data-cy": "contactmail"}}
                    variant="standard" />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    onChange={(event)=>dispatch(changeResidenceFieldValue({field: "remarks", value: event?.target.value}))}
                    value={residenceFieldsStoreValues.remarks}
                    error={errorData.remarks}
                    inputProps={{"data-cy": "remarks"}}
                    label= {t("residence.remarks")}
                    variant="standard" />
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} container {...displayProp(1)}>
            <Card sx={{width: "100%", padding: 3}}>
              <CardHeader
                className="p-0 pb-3"
                avatar={
                  <Avatar aria-label="recipe" style={{
                    color: "#63c2de",
                    backgroundColor: "#f0f3f5",
                  }}>
                    <HomeIcon data-toggle="popover" data-content="Disabled popover" />
                  </Avatar>
                }
                title={t("residence.rooms.title")}
              />
              {roomData.roomFields&&[...Array(roomData.roomFields?.length)]
                  .map((room, roomIndex)=>(!roomData.roomFields[roomIndex].deleted&&<Card key={roomIndex} sx={{padding: 3, marginY: 2}}>
                    <Grid container item spacing={3}>
                      <Grid item container xs={11} justifyContent='space-between'>
                        <Grid item xs={5}>
                          <TextField
                            fullWidth
                            onChange={(event)=>dispatch(changeRoomNameValue({roomIndex: roomIndex, value: event?.target.value}))}
                            value={roomData.roomFields[roomIndex].name}
                            label= {t("room.name")}
                            inputProps={{"data-cy": `room_${roomIndex}`}}
                            variant="standard" />
                        </Grid>
                        <Grid item xs={5}>
                          <FormControl fullWidth>
                            <InputLabel >{t("appartment.room.type")}</InputLabel>
                            <Select
                              variant="standard"
                              id="demo-simple-select"
                              value={roomData.roomFields[roomIndex].room_type}
                              error={errorData.locomotion_help}
                              label={t("appartment.room.type")}
                              onChange={(event)=>dispatch(changeRoomTypeValue({roomIndex: roomIndex, value: event?.target.value}))}
                            >
                              <MenuItem value='Bedroom'>{t("appartment.roomType.bedroom")}</MenuItem>
                              <MenuItem value='Living Room'>{t("appartment.roomType.livingroom")}</MenuItem>
                              <MenuItem value='Kitchen'>{t("appartment.roomType.kitchen")}</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        {roomData.roomFields&&[...Array(roomData.roomFields[roomIndex].equipment?.length)]
                            .map((equipment, equipmentIndex)=>
                              <Grid item container key={`${roomIndex}_${equipmentIndex}`} flexDirection="row" spacing="3">
                                <Grid item xs={5}>
                                  <TextField
                                    fullWidth
                                    inputProps={{"data-cy": `equipment_${roomIndex}_${equipmentIndex}`}}
                                    onChange={(event)=>
                                      dispatch(changeEquipmentValue({
                                        roomIndex: roomIndex,
                                        equipmentIndex: equipmentIndex,
                                        value: event?.target.value}))}
                                    // we need to save and get equipment from db
                                    value={roomData?.roomFields[roomIndex]?.equipment[equipmentIndex]}
                                    label= {t("equipment.name")}
                                    variant="standard" />
                                </Grid>
                                <Grid item xs={7} alignSelf="end">
                                  <Button
                                    color="error"
                                    data-cy={`equipmentRemove_${roomIndex}_${equipmentIndex}`}
                                    onClick={()=>dispatch(removeEquipment({roomIndex, equipmentIndex}))} >
                                    <FaTrash/>
                                  </Button>
                                </Grid>
                              </Grid>,
                            )}
                      </Grid>
                      <Grid item xs={1}>
                        <Button
                          color="error"
                          data-cy={`roomRemove_${roomIndex}`}
                          onClick={()=>dispatch(removeRoom({roomIndex}))} >
                          <FaTrash/>
                        </Button>

                      </Grid>
                    </Grid>
                    <CardActions>
                      <Button data-cy={`equipmentAdd_${roomIndex}`} onClick={()=>dispatch(addEquipmentToRoom({roomIndex}))} variant="outlined">{t("equipment.add.new")}</Button>
                    </CardActions>
                  </Card>))}
              <CardActions>
                <Button data-cy={"roomAdd"} onClick={()=>dispatch(addRoom())} variant="outlined">{t("room.add.new")}</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <CardActions sx={{width: "100%"}}>
          <Grid item xs={12}>
            <Toolbar sx={{justifyContent: "space-between", flexDirection: "row-reverse"}}>
              {stepperPage===1&&<Button data-cy={"saveButton"} variant="contained" onClick={handlePostAppartment}>{t("form.button.save")}</Button>}
              {stepperPage===1&&<Button variant="text" onClick={()=>setStepperPage(0)}>{t("form.button.prev")}</Button>}
              {stepperPage===0&&<Button data-cy="nextButton" variant="contained" onClick={()=>setStepperPage(1)}>{t("form.button.next")}</Button>}
            </Toolbar>
          </Grid>
        </CardActions>
      </Grid>
    </Card>
  );
}
