const flatNestedObject=(obj)=>{
  return Object.assign({}, ...function _flatten(o) {
    return o && [].concat(...Object.keys(o).map((k) => typeof o[k] === "object" ? _flatten(o[k]) : ({[k]: o[k]})));
  }( obj));
};

export const saveCsv=(data:any[])=>{
  const dataForCsv=data?.map((e)=>flatNestedObject(e));
  if (dataForCsv?.length>0) {
    const csvContent = "data:text/csv;charset=utf-8," +
          Object.keys(dataForCsv[0]).map((e) => e)+"\n"+
          dataForCsv?.map((e) => Object.values(e).join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", data[0].sensor_name);
    document.body.appendChild(link);
    link.click();
  }
};
