import {i18_instance} from "utils/i18n";
import {PublicClientApplication} from "@azure/msal-browser";
import {setAuthenticatedUserContext} from "utils/AppInsights";

const msalConfig = {
  auth: {
    authority: "https://login.microsoftonline.com/5648b8a2-4259-47bc-b4a1-980bb677ef67",
    clientId: "5a61039f-6f29-44f1-aaee-cf2b4627d8c5",
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
    secureCookies: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        console.debug("[MSAL LOG] ", message);
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 10000,
    asyncPopups: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
console.debug("[MSAL] INSTANCE CREATED");

msalInstance.addEventCallback((message) => {
  console.debug("[MSAL EVENT] ", message.eventType);
  try {
    if (message.eventType === "msal:loginSuccess") {
      setAuthenticatedUserContext(message.payload.account.username); // OID
    }
    if (message.eventType === "msal:acquireTokenSuccess") {
      setAuthenticatedUserContext(message.payload.account.username); // OID
    }
    if (message.eventType === "msal:acquireTokenFailure") {
      console.debug("[MSAL] AUTO LOGIN");
      logout();
    }
    if (message.error) {
      console.debug("[MSAL] ERROR ", JSON.stringify(message));
    }
  } catch (ex) {
    console.debug("[MSAL] ERROR ", ex);
  }
});

export const acquireAccessToken = async () => {
  try {
    console.debug("[MSAL EVENT] acquireAccessToken");
    const accounts = msalInstance.getAllAccounts();
    const request = {
      scopes: ["openid", "profile", "email", "offline_access"],
      account: accounts[0],
    };
    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.idToken;
  } catch (ex) {
    if (ex && ex.errorMessage && ex.errorMessage.indexOf("AADB2C90077") > -1) {
      console.debug("[MSAL] ERROR AADB2C90077");
      // eslint-disable-next-line
      // FIX: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2016
      try {
        msalInstance.acquireTokenRedirect(request);
      } catch (ex) {
        console.error(ex);
      }
    } else {
      console.debug("[MSAL] ERROR ", ex.errorMessage);
    }
  }
};

export const login = async () => {
  try {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      await logout();
      console.debug("[MSAL] ERROR LOGIN: ALLREADY LOGGED IN");
    } else {
      await msalInstance.loginRedirect({
        scopes: ["openid", "profile", "email", "offline_access"],
        extraQueryParameters: {"lang": i18_instance.i18n.language},
      }).catch( (error)=>{
        console.error("[MSAL] ERROR LOGIN: ", error);
      });
    }
  } catch (ex) {
    console.error("[MSAL] ERROR LOGIN: ", ex);
  }
};

export const logout = async () => {
  try {
    console.debug("[MSAL EVENT] LOGOUT");
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length <= 0) {
      console.debug("[MSAL EVENT] USER NOT LOGGED IN");
    } else {
      await msalInstance.logoutRedirect();
      await removeCookies();
    }
  } catch (ex) {
    console.debug("[MSAL] ERROR LOGOUT: ", ex);
    console.error(ex);
  }
};

export const profileEdit = async () => {
  // not implemented
};

export const passwordEdit = async () => {
  // not implemented
};

function removeCookies() {
  try {
    console.debug("[MSAL EVENT] removeCookies");
    const cookies = document.cookie.split("; ");
    eraseCookieFromAllPaths("ai_session");
    eraseCookieFromAllPaths("ai_user");
    for (let c = 0; c < cookies.length; c++) {
      if (cookies[c].startsWith("gdprDismiss")) {
        continue;
      }
      const d = window.location.hostname.split(".");
      while (d.length > 0) {
        const cookieBase = encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" + d.join(".") + " ;path=";
        const p = location.pathname.split("/");
        document.cookie = cookieBase + "/";
        while (p.length > 0) {
          document.cookie = cookieBase + p.join("/");
          p.pop();
        }
        d.shift();
      }
    }
  } catch (ex) {
    console.error(ex);
  }
}
function eraseCookieFromAllPaths(name) {
  // This function will attempt to remove a cookie from all paths.
  const pathBits = location.pathname.split("/");
  let pathCurrent = " path=";
  // do a simple pathless delete first.
  document.cookie = name + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT;";
  for (let i = 0; i < pathBits.length; i++) {
    pathCurrent += ((pathCurrent.substr(-1) != "/") ? "/" : "") + pathBits[i];
    document.cookie = name + "=; expires=Thu, 01-Jan-1970 00:00:01 GMT;" + pathCurrent + ";";
  }
}
