import React, {useEffect, useState} from "react";
import {
  Grid,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CardActions,
  Toolbar,
  Button,
  CardHeader,
  Avatar,
  TextField,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import HexagonOutlinedIcon from "@mui/icons-material/HexagonOutlined";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {UseAppSelector, useAnyDispatch} from "store/hooks";
import Backdrop from "components/form/Backdrop";
import {
  changeSensorFieldValue,
  clearSensorFormFields,
  postSensor,
  selectErrorFields,
  selectSensorFields,
  selectLoadingSensorForm,
  editSensor,
  getSensor,
  getHubs,
} from "./sensorFormSlice";
import {
  fetchAllAppartments,
  selectAppartments,
} from "features/AppartmentList/AppartmentListSlice";
import {
  getAppartment,
  selectRoomData,
  selectHubs,
} from "features/ApartmentForm/apartmentFormSlice";

const steinelMenuItems = [
  {value: "TemperatureCorrection", label: "Temperature Correction"},
  {value: "Presence1HoldTime", label: "Presence Hold Time"},
  {value: "TruePresenceSettingsScenario", label: "True Presence Settings Scenario"},
  {value: "Presence1", label: "Presence 1"},
  {value: "TruePresenceSettingsRadius", label: "True Presence Settings Radius"},
  {value: "Motion1COV", label: "Motion 1 COV"},
  {value: "TruePresence1", label: "True Presence 1"},
  {value: "Brightness1", label: "Brightness 1"},
  {value: "BluetoothEnabled", label: "Bluetooth Enabled"},
  {value: "TruePresenceSettingsHeight", label: "True Presence Settings Height"},
  {value: "DetectorFWVersion", label: "Detector Firmware Version"},
  {value: "HumidityCOV", label: "Humidity COV"},
  {value: "TruePresence1COV", label: "True Presence 1 COV"},
  {value: "Motion1", label: "Motion 1"},
  {value: "SensorName", label: "Sensor Name"},
  {value: "TemperatureCOV", label: "Temperature COV"},
  {value: "Presence1COV", label: "Presence 1 COV"},
  {value: "Humidity", label: "Humidity"},
  {value: "Temperature", label: "Temperature"},
  {value: "SensorSWVersion", label: "Sensor Software Version"},
  {value: "Brightness1COV", label: "Brightness 1 COV"},
  {value: "AerosolInfRiskTGA", label: "Aerosol Inf Risk TGA"},
  {value: "DewPoint", label: "Dew Point"},
  {value: "AerosolRiskOfInfectionStatus", label: "Aerosol Risk Of Infection Status"},
  {value: "SensorLEDPattern", label: "Sensor LED Pattern"},
  {value: "VOCCOV", label: "VOC COV"},
  {value: "CO2COV", label: "CO2 COV"},
  {value: "AerosolAirHyst", label: "Aerosol Air Hyst"},
  {value: "AerosolStaleAir", label: "Aerosol Stale Air"},
  {value: "BuzzerSetting", label: "Buzzer Setting"},
  {value: "AerosolAirTGA", label: "Aerosol Air TGA"},
  {value: "CO2TGA", label: "CO2 TGA"},
  {value: "AirPressureCOV", label: "Air Pressure COV"},
  {value: "Online", label: "Online"},
  {value: "NoiseCOV", label: "Noise COV"},
  {value: "AerosolRiskOfInfectionCOV", label: "Aerosol Risk Of Infection COV"},
  {value: "CO2Hyst", label: "CO2 Hyst"},
  {value: "DewPointCOV", label: "Dew Point COV"},
  {value: "CO2", label: "CO2"},
  {value: "Noise", label: "Noise"},
  {value: "AerosolRiskOfInfectionStatusCOV", label: "Aerosol Risk Of Infection Status COV"},
  {value: "AerosolStaleAirCOV", label: "Aerosol Stale Air COV"},
  {value: "ComfortZone", label: "Comfort Zone"},
  {value: "ComfortZoneUpperHum", label: "Comfort Zone Upper Hum"},
  {value: "AerosolStaleAirStatusCOV", label: "Aerosol Stale Air Status COV"},
  {value: "AerosolAirTAR", label: "Aerosol Air TAR"},
  {value: "AerosolInfRiskTAR", label: "Aerosol Inf Risk TAR"},
  {value: "LEDSetting", label: "LED Setting"},
  {value: "AirPressure", label: "Air Pressure"},
  {value: "AerosolStaleAir", label: "Aerosol Stale Air"},
  {value: "VOCCOV", label: "VOC COV"},
  {value: "CO2Hyst", label: "CO2 Hyst"},
  {value: "DewPoint", label: "Dew Point"},
  {value: "AerosolRiskOfInfectionStatus", label: "Aerosol Risk Of Infection Status"},
  {value: "DetectorFWVersion", label: "Detector Firmware Version"},
  {value: "AerosolRiskOfInfection", label: "Aerosol Risk Of Infection"},
  {value: "LEDSetting", label: "LED Setting"},
  {value: "CO2TAR", label: "CO2 TAR"},
  {value: "AerosolStaleAirStatus", label: "Aerosol Stale Air Status"},
  {value: "Brightness1", label: "Brightness 1"},
  {value: "AerosolAirTAR", label: "Aerosol Air TAR"},
  {value: "Presence1HoldTime", label: "Presence Hold Time"},
];


export function SensorForm() {
  const {t} = useTranslation();
  const dispatch=useAnyDispatch();
  const navigate=useNavigate();
  const {id}=useParams();

  const [appartmentId, setAppartmentid]=useState("");
  // const [hubId, setHubId]=useState(0);
  const [sensorType, setSetSensorType]=useState("");

  const sensorFieldsStoreValues=UseAppSelector(selectSensorFields);
  const appartments=UseAppSelector(selectAppartments);
  const hubs=UseAppSelector(selectHubs);
  const rooms=UseAppSelector(selectRoomData);
  const errorData=UseAppSelector(selectErrorFields);
  const loading=UseAppSelector(selectLoadingSensorForm);

  useEffect(()=>{
    dispatch(clearSensorFormFields());
    dispatch(fetchAllAppartments());
    if (id) {
      dispatch(getSensor(id))
          .then((res)=>{
            setAppartmentid(res.payload.result.room.appartment_id);
            dispatch(getHubs(res.payload.result.sensor_type));
          });
    }
  }, []);

  useEffect(()=>{
    dispatch(getHubs(sensorFieldsStoreValues.sensor_type));
  }, [sensorType]);

  useEffect(()=>{
    if (parseInt(sensorFieldsStoreValues.hub_id.toString()) > 0) {
      const hub = hubs.find( (hub) => hub.hub_id === sensorFieldsStoreValues.hub_id );
      dispatch(changeSensorFieldValue({field: "hub_name", value: hub?.name??""}));
      dispatch(changeSensorFieldValue({field: "access_token", value: hub?.configuration?.access_token??""}));
      dispatch(changeSensorFieldValue({field: "refresh_token", value: hub?.configuration?.refresh_token??""}));
      dispatch(changeSensorFieldValue({field: "client_id", value: hub?.configuration?.client_id??""}));
      dispatch(changeSensorFieldValue({field: "client_secret", value: hub?.configuration?.client_secret??""}));
      dispatch(changeSensorFieldValue({field: "application_key", value: hub?.configuration?.application_key??""}));
      dispatch(changeSensorFieldValue({field: "client_id", value: hub?.configuration?.client_id??""}));
      dispatch(changeSensorFieldValue({field: "password", value: hub?.configuration?.password??""}));
      dispatch(changeSensorFieldValue({field: "topic", value: hub?.configuration?.topic??""}));
    } else {
      dispatch(changeSensorFieldValue({field: "hub_name", value: ""}));
      dispatch(changeSensorFieldValue({field: "access_token", value: ""}));
      dispatch(changeSensorFieldValue({field: "refresh_token", value: ""}));
      dispatch(changeSensorFieldValue({field: "client_id", value: ""}));
      dispatch(changeSensorFieldValue({field: "client_secret", value: ""}));
      dispatch(changeSensorFieldValue({field: "application_key", value: ""}));
      dispatch(changeSensorFieldValue({field: "client_id", value: ""}));
      dispatch(changeSensorFieldValue({field: "password", value: ""}));
      dispatch(changeSensorFieldValue({field: "topic", value: ""}));
    }
  }, [hubs, sensorFieldsStoreValues.hub_id]);

  useEffect(()=>{
    if (appartmentId) {
      dispatch(getAppartment(appartmentId));
    }
  }, [appartmentId]);

  const handleSaveSensor = async () => {
    try {
      let res;
      if (id) {
        await dispatch(editSensor({sensor_id: id, sensorEditData: sensorFieldsStoreValues}));
      } else {
        res = await dispatch(postSensor(sensorFieldsStoreValues));
        console.log("postSensor res:", res);
      }
      if (res.error) {
        // nothing
      } else {
        navigate("/sensors", {replace: true});
      }
    } catch (error) {
      console.error("postSensor error:", error);
      // Handle the error as needed
    }
  };

  const [stepperPage, setStepperPage]=useState(0);
  const steps=["sensors"]
      .map((e, idx)=>({
        key: t(`entry.step.${e}`),
        title: window.innerWidth>
        768|| stepperPage===idx? t(`entry.step.${e}`):"",
        onClick: () => setStepperPage(idx),
      }));

  const displayProp=(pageNumber:typeof stepperPage)=>({display: stepperPage===pageNumber?"flex" as "flex":"none" as "none"});

  // Function to generate MenuItem components for Steinel menu items
  const renderSteinelMenuItems = () => {
    return steinelMenuItems.map((item, index) => (
      <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
    ));
  };


  return (
    <Card sx={{width: "100%", padding: 1}}>
      <Grid container>
        <Grid item xs={12}>
          <Stepper nonLinear activeStep={stepperPage} alternativeLabel>
            {steps.map((e, index) =><Step key={e.key} onClick={e.onClick}><StepLabel >{e.title}</StepLabel></Step>)}
          </Stepper>
        </Grid>
        <Grid item xs={12} container {...displayProp(0)}>
          <Card sx={{width: "100%", padding: 3}}>
            <CardHeader
              className="p-0 pb-3"
              sx={{marginTop: "50px", marginBottom: "20px"}}
              avatar={
                <Avatar aria-label="recipe" style={{
                  color: "#63c2de",
                  backgroundColor: "#f0f3f5",
                }}>
                  <HexagonOutlinedIcon data-toggle="popover" data-content="Disabled popover" fontSize="large"/>
                </Avatar>
              }
              title={t("label.newSensor")}
            />
            <Backdrop open={Object.values(loading).includes("pending")}/>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid item xs={6}>
                  <FormControl fullWidth data-cy={"appartmentId"}>
                    <InputLabel >{t("sensor.appartment")}</InputLabel>
                    <Select
                      value={appartmentId}
                      // inputProps={{"data-cy": "appartmentId"}}
                      label={t("sensor.appartment")}
                      onChange={(event)=>setAppartmentid( event?.target.value)}
                    >{appartments&&appartments.map((appartment, index)=>
                        <MenuItem key={index} value={appartment.appartment_id}>{appartment.name}</MenuItem>,
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={6}>
                  <FormControl fullWidth data-cy={"roomId"}>
                    <InputLabel >{t("sensor.room")}</InputLabel>
                    <Select
                      disabled={!appartmentId}
                      value={sensorFieldsStoreValues.room_id}
                      label={t("sensor.room")}
                      // inputProps={{"data-cy": "roomId"}}
                      onChange={(event:any)=>dispatch(changeSensorFieldValue({field: "room_id", value: event?.target.value}))}
                    >{rooms.roomFields&&rooms.roomFields.map((room, index)=>
                        <MenuItem key={index} value={room.room_id}>{room.name}</MenuItem>,
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={6}>
                  <FormControl fullWidth data-cy={"sensorType"}>
                    <InputLabel >{t("sensor.type")}</InputLabel>
                    <Select
                      disabled={!appartmentId||sensorFieldsStoreValues.room_id===""}
                      value={sensorFieldsStoreValues.sensor_type}
                      label={t("sensor.type")}
                      // inputProps={{"data-cy": "sensorType"}}
                      onChange={(event:any)=>{
                        setSetSensorType(event?.target.value);
                        dispatch(changeSensorFieldValue({field: "sensor_type", value: event?.target.value}));
                      }}
                    >
                      <MenuItem value={"qumea"}>QUMEA</MenuItem>
                      <MenuItem value={"hue"}>HUE</MenuItem>
                      <MenuItem value={"steinel"}>STEINEL</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={6}>
                  <TextField
                    disabled={!appartmentId||sensorFieldsStoreValues.room_id===""}
                    onChange={(event)=>dispatch(changeSensorFieldValue({field: "name", value: event?.target.value}))}
                    value={sensorFieldsStoreValues.name}
                    error={errorData.name}
                    label= {t("sensor.sensor_name")}
                    inputProps={{"data-cy": "name"}}
                    fullWidth
                    variant="standard" />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={6} >
                  <div hidden={
                    sensorFieldsStoreValues.sensor_type !== "hue" &&
                    sensorFieldsStoreValues.sensor_type !== "qumea"
                  }>
                    <TextField
                      disabled={!appartmentId||sensorFieldsStoreValues.room_id===""}
                      onChange={(event)=>dispatch(changeSensorFieldValue({field: "serial_number", value: event?.target.value}))}
                      value={sensorFieldsStoreValues.serial_number}
                      error={errorData.serial_number}
                      label= {t("sensor.serialNumber")}
                      inputProps={{"data-cy": "serialNumber"}}
                      fullWidth
                      variant="standard" />
                  </div>


                </Grid>
              </Grid>
              {sensorFieldsStoreValues.sensor_type?
              <React.Fragment>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <FormControl fullWidth data-cy={"hub"}>
                      <InputLabel >{t("sensor.configuration")}</InputLabel>
                      <Select
                        disabled={!appartmentId||sensorFieldsStoreValues.room_id===""}
                        value={parseInt(sensorFieldsStoreValues.hub_id.toString()) || 0}
                        label={t("sensor.configuration")}
                        inputProps={{"data-cy": "sensorType"}}
                        onChange={(event:any)=>{
                          dispatch(changeSensorFieldValue({field: "hub_id", value: parseInt(event?.target.value)}));
                        }}
                      >
                        <MenuItem value={0}>{t("sensor.new_configuration")}</MenuItem>
                        {hubs&&hubs.map((hub, index)=> {
                          return hub.name?<MenuItem key={index + 1} value={hub.hub_id}>{hub.name}</MenuItem>:null;
                        },
                        )}
                      </Select>
                    </FormControl>


                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(event)=>dispatch(changeSensorFieldValue({field: "hub_name", value: event?.target.value}))}
                      value={sensorFieldsStoreValues.hub_name}
                      error={errorData.hub_name}
                      label= {t("sensor.configuration_name")}
                      inputProps={{"data-cy": "name"}}
                      fullWidth
                      variant="standard" />
                  </Grid>
                </Grid>
                {sensorFieldsStoreValues.sensor_type === "hue"?
              <React.Fragment>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(event)=>dispatch(changeSensorFieldValue({field: "access_token", value: event?.target.value}))}
                      value={sensorFieldsStoreValues.access_token}
                      error={errorData.access_token}
                      label= {t("sensor.access_token")}
                      inputProps={{"data-cy": "access_token"}}
                      fullWidth
                      variant="standard" />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(event)=>dispatch(changeSensorFieldValue({field: "refresh_token", value: event?.target.value}))}
                      value={sensorFieldsStoreValues.refresh_token}
                      error={errorData.refresh_token}
                      label= {t("sensor.refresh_token")}
                      inputProps={{"data-cy": "refresh_token"}}
                      fullWidth
                      variant="standard" />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(event)=>dispatch(changeSensorFieldValue({field: "application_key", value: event?.target.value}))}
                      value={sensorFieldsStoreValues.application_key}
                      error={errorData.application_key}
                      label= {t("sensor.application_key")}
                      inputProps={{"data-cy": "application_key"}}
                      fullWidth
                      variant="standard" />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(event)=>dispatch(changeSensorFieldValue({field: "client_id", value: event?.target.value}))}
                      value={sensorFieldsStoreValues.client_id}
                      error={errorData.client_id}
                      label= {t("sensor.client_id")}
                      inputProps={{"data-cy": "client_id"}}
                      fullWidth
                      variant="standard" />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(event)=>dispatch(changeSensorFieldValue({field: "client_secret", value: event?.target.value}))}
                      value={sensorFieldsStoreValues.client_secret}
                      error={errorData.client_secret}
                      label= {t("sensor.client_secret")}
                      inputProps={{"data-cy": "client_secret"}}
                      fullWidth
                      variant="standard" />
                  </Grid>
                </Grid>
              </React.Fragment> :
              null }
                {sensorFieldsStoreValues.sensor_type == "qumea"?
              <React.Fragment>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(event)=>dispatch(changeSensorFieldValue({field: "client_id", value: event?.target.value}))}
                      value={sensorFieldsStoreValues.client_id}
                      error={errorData.client_id}
                      label= {t("sensor.client_id")}
                      inputProps={{"data-cy": "client_id"}}
                      fullWidth
                      variant="standard" />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(event)=>dispatch(changeSensorFieldValue({field: "password", value: event?.target.value}))}
                      value={sensorFieldsStoreValues.password}
                      error={errorData.password}
                      label= {t("sensor.password")}
                      inputProps={{"data-cy": "password"}}
                      fullWidth
                      variant="standard" />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <TextField
                      onChange={(event)=>dispatch(changeSensorFieldValue({field: "topic", value: event?.target.value}))}
                      value={sensorFieldsStoreValues.topic}
                      error={errorData.topic}
                      label= {t("sensor.topic")}
                      inputProps={{"data-cy": "topic"}}
                      fullWidth
                      variant="standard" />
                  </Grid>
                </Grid></React.Fragment> :
              null }
                {sensorFieldsStoreValues.sensor_type == "steinel"?
              <React.Fragment>
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <Select
                      value={sensorFieldsStoreValues.topic}
                      onChange={(event) => dispatch(changeSensorFieldValue({field: "topic", value: event.target.value}))}
                      label={t("sensor.topic")}
                      error={errorData.topic}
                      fullWidth
                      variant="standard"
                      inputProps={{"data-cy": "steinel_topic"}}
                    >
                      <MenuItem value="alltopic">{t("sensor.steinel.alltopics")}</MenuItem>

                    </Select>

                  </Grid>
                </Grid></React.Fragment> :
              null }
              </React.Fragment>:
              null }
            </Grid>
          </Card>
        </Grid>
        <CardActions sx={{width: "100%"}}>
          <Grid item xs={12}>
            <Toolbar sx={{justifyContent: "space-between", flexDirection: "row-reverse"}}>
              <Button variant="contained" data-cy={"saveButton"} onClick={handleSaveSensor}>{t("form.button.save")}</Button>
            </Toolbar>
          </Grid>
        </CardActions>
      </Grid>
    </Card>

  );
}
