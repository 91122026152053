import React from "react";
import {
  Backdrop,
  CircularProgress,
} from "@mui/material";


export default function SimpleBackdrop(props) {
  let openme = false;
  if (props.open === true) {
    openme = true;
  }
  return (
    <div>
      <Backdrop sx={{zIndex: "100", color: "#fff"}} open={openme}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
