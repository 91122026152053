import React, {useEffect, useState} from "react";
import {
  Card,
  CardContent,
  Grid,
  Collapse,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import {ExpandMore, MoreVert} from "@mui/icons-material";

type ListCardProps={
    title:string,
    subtitle:string,
    dataCyId?:number,
    body:any,
    CollapseContent:any,
    actionDropdown:any,
    statusIcon?:any,
    getCollapseDetails?:any,
    page?:number,
}


export default ({
  title,
  body,
  subtitle,
  CollapseContent,
  dataCyId,
  actionDropdown,
  statusIcon,
  getCollapseDetails,
  page,
}:ListCardProps) =>{
  const [expanded, setExpanded]=useState(false);
  const [anchorMenu, setAnchorMenu]=useState<null | HTMLElement>(null);

  const open = Boolean(anchorMenu);

  useEffect(()=>{
    expanded&&handleMenuClose();
    expanded&&handleExpandClick();
  }, [page]);

  const handleExpandClick=() => {
    setExpanded(!expanded);
    !expanded&&getCollapseDetails?getCollapseDetails():null;
  };
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorMenu(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorMenu(null);
  };

  const dottedSeparator={
    border: "none",
    marginTop: "initial",
    marginBottom: "initial",
    borderTop: "1px dotted grey",
    width: "100%",
  };

  const background={
    "backgroundColor": "#f0f3f5",
    "padding": "6px",
  };
  return <>
    {/* dropdown actions */}
    <Menu
      id="long-menu"
      MenuListProps={{
        "aria-labelledby": "long-button",
      }}
      anchorEl={anchorMenu}
      open={open}
      onClose={handleMenuClose}
    >
      {actionDropdown.map((option) => (
        <MenuItem key={option.name}
          data-cy={option.datacy}
          onClick={()=>{
            option.action();
            handleMenuClose();
          }}>
          {option.name}
        </MenuItem>
      ))}
    </Menu>
    <Card>
      <CardContent>
        <Grid container >
          {/* Title component */}
          <Grid container item alignItems="center">
            <Grid item xs={statusIcon?8:11} >
              <Grid item>
                <Typography component={"span"} color="#2f353a" gutterBottom>
                  {subtitle}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography component={"span"} variant="h5">
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                {body}
              </Grid>
            </Grid>
            {statusIcon&&<Grid item xs={3} >
              <IconButton
                id="actionsButton"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
              >
                {statusIcon}
              </IconButton>
            </Grid>}
            <Grid item xs={1} >
              <IconButton
                id="actionsButton"
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                data-cy={"actionMenu_"+dataCyId}
                onClick={handleMenuClick}
              >
                <MoreVert />
              </IconButton>
            </Grid>
            {/* Collapse component */}
            <Grid item xs={12} flexDirection='row' display='flex' alignItems='center'>
              <Grid item >
                <IconButton
                  id="expandButton"
                  sx={{transform: expanded?"rotate(180deg)" :"rotate(0deg)"}}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                  style={{paddingLeft: "0"}}
                >
                  <ExpandMore />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit style={{...dottedSeparator, ...background}}>
        {CollapseContent}
      </Collapse>
    </Card>
  </>;
};

