import React, {useState} from "react"; import {
  Grid,
  Card,
  CardHeader,
  Avatar,
  TextField,
  FormControl,
  IconButton,
  FormLabel,
  Checkbox,
  FormControlLabel,
  Typography,
  FormGroup,
  CardContent,
  InputLabel,
  Select,
  MenuItem,
  Switch,
} from "@mui/material";
import moment from "moment-timezone";

import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import LogoutIcon from "@mui/icons-material/Logout";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DesktopDatePicker} from "@mui/x-date-pickers/DesktopDatePicker";
import {enUS, de} from "date-fns/esm/locale";
import {useTranslation} from "react-i18next";
import {UseAppSelector, UseAppDispatch} from "store/hooks";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  selectBookingFields,
  changeBookingFieldValue,
  selectAvailableAppartments,
} from "../patientFormSlice";

import {formatDateDisplay} from "utils/formatting";
import BookingsCollapse from "components/BookingsCollapse";
import DoneIcon from "@mui/icons-material/Done";


export function BookingForm() {
  const {t, i18n} = useTranslation();
  const dispatch = UseAppDispatch();

  const bookingFieldsStoreValues = UseAppSelector(selectBookingFields);
  const appartments=UseAppSelector(selectAvailableAppartments);


  const selectAppartmentHandler=(id:number)=>{
    if (id===bookingFieldsStoreValues.appartment_id) {
      dispatch(changeBookingFieldValue({field: "appartment_id", value: ""}));
    } else {
      dispatch(changeBookingFieldValue({field: "appartment_id", value: id}));
    }
  };

  const presences = bookingFieldsStoreValues.presences || [];
  const handleAddPresence = async () => {
    const newPresences = [
      ...presences,
      {
        presence_id: presences.length + 1,
        presence_from: 1,
        presence_to: 24,
        is_expected: false,
        weekday: 0,
      },
    ];
    dispatch(changeBookingFieldValue({field: "presences", value: newPresences}));
    return;
  };
  const handleRemovePresence = (id: number) => {
    const newPresences = bookingFieldsStoreValues.presences.filter((presence) => presence.presence_id !== id);
    console.debug("handleRemovePresence", newPresences);
    dispatch(changeBookingFieldValue({field: "presences", value: newPresences}));
  };

  const handleChangePresence = (id: number, field: string, value: any) => {
    console.debug("handleChangePresence", id, field, value);
    if (field==="presence_from" || field==="presence_to") {
      const number = parseFloat(value);
      // Check if the input is a number and within the range 0 to 24
      if (isNaN(number) || number < 0 || number > 24) {
        value = 0; // Invalid input
      }
    }
    const newPresences = bookingFieldsStoreValues.presences.map((presence) =>
      presence.presence_id === id ? {...presence, [field]: value} : presence,
    );
    console.debug("handleChangePresence", newPresences);

    dispatch(changeBookingFieldValue({field: "presences", value: newPresences}));
  };
  console.log("bookingFieldsStoreValues", bookingFieldsStoreValues);

  return (
    <Card sx={{padding: 3, width: "100%"}}>
      <CardHeader
        className="p-0 pb-3"
        avatar={
          <Avatar aria-label="recipe" style={{
            color: "#63c2de",
            backgroundColor: "#f0f3f5",
          }}>
            <LogoutIcon data-toggle="popover" data-content="Disabled popover" />
          </Avatar>
        }
        title={t("entry.step.roomBooking")}
      />
      <Grid container spacing={3} paddingBottom={3} alignContent='center' justifyContent='center'>
        <Grid item container xs={12}>
          <Grid item container xs={12} sm={5} alignItems='center' >
            <Typography>{t("patient.booking.startDate")}</Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language==="DE"?de:enUS}>
              <DesktopDatePicker
                value={bookingFieldsStoreValues.check_in_date}
                // error={errorData.check_in_date}
                openTo="year"
                views={["year", "month", "day"]}
                inputFormat="dd/MM/yyyy"
                onChange={(date:any)=>dispatch(changeBookingFieldValue({field: "check_in_date", value: date}))}
                renderInput={(params) => <TextField data-cy={"checkIn"} {...params} />}
              >
              </DesktopDatePicker>
            </LocalizationProvider>
          </Grid>
          <Grid item container xs={12} sm={5} alignItems='center'>
            <Typography>{t("patient.booking.endDate")}</Typography>
          </Grid>
          <Grid item container xs={12} sm={7}>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={i18n.language==="DE"?de:enUS}>
              <DesktopDatePicker
                value={bookingFieldsStoreValues.check_out_date}
                // error={errorData.check_out_date}
                openTo="year"
                views={["year", "month", "day"]}
                minDate={Date.parse(bookingFieldsStoreValues.check_in_date.toString())}
                inputFormat="dd/MM/yyyy"
                onChange={(date:any)=>dispatch(changeBookingFieldValue({field: "check_out_date", value: date}))}
                renderInput={(params) => <TextField data-cy={"checkOut"} {...params} />}
              >
              </DesktopDatePicker>
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} paddingY={3}>
        <Typography variant='h5'>{t("booking.availableAppartments")}</Typography>
      </Grid>
      <Grid item container spacing={3} xs={12}>
        {appartments?appartments.map((appartment, index)=><Grid item xs={12} sm={6} md={4} key={index} >
          <Card sx={{minHeight: "250px",
            border: "5px  solid",
            borderColor: appartment.appartment_id===bookingFieldsStoreValues.appartment_id?"#63c2de":appartment.bookings[0].booking_id?"#f86c6b":"#56b472"}}>
            <CardHeader
              className="p-0 pb-3"
              title={`${t("appartment.name")} ${appartment.name}`}
              action= {!appartment.bookings[0]?.booking_id&&<IconButton data-cy={`select_${appartment.appartment_id}`}
                aria-label="settings" onClick={()=>selectAppartmentHandler(appartment.appartment_id)}>
                {appartment.appartment_id!==bookingFieldsStoreValues.appartment_id?<AddCircleOutlineIcon color="primary" />:
                <DoneIcon color="secondary" />}
              </IconButton>
              }
            />
            <CardContent>
              <Typography>{`${t("booking.appartment.location")} ${appartment.location}`}</Typography>
              <Typography>{`${t("booking.appartment.street")} ${appartment.street}`}</Typography>
              {appartment.bookings[0]?.booking_id&&appartment.bookings.map((booking, index)=>{
                return <BookingsCollapse key={index}>
                  <Typography marginTop='10px'>{`${t("booking.check.in")} ${formatDateDisplay(booking.check_in_date)}`}</Typography>
                  <Typography>{`${t("booking.check.in")} ${formatDateDisplay(booking.check_out_date)}`}</Typography>
                </BookingsCollapse>;
              })}
            </CardContent>
          </Card>
        </Grid>):<Typography>{t("no.appartments")}</Typography>}
      </Grid>
      <Grid item container xs={12} paddingY={3}>
        <FormControl>
          <FormLabel>{t("booking.status")}</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={Boolean(bookingFieldsStoreValues.booking_status_admission)}
                  onChange={(event)=>dispatch(changeBookingFieldValue({field: "booking_status_admission", value: event?.target.checked}))} name="Admission" />
              }
              label={t("addmision.data")}
            />
            <FormControlLabel
              control={
                <Checkbox checked={Boolean(bookingFieldsStoreValues.booking_status_visit)}
                  onChange={(event)=>dispatch(changeBookingFieldValue({field: "booking_status_visit", value: event?.target.checked}))} name="Visit" />
              }
              label={t("visit.information")}
            />
            <FormControlLabel
              control={
                <Checkbox checked={Boolean(bookingFieldsStoreValues.booking_status_follow_up)}
                  onChange={(event)=>dispatch(changeBookingFieldValue({field: "booking_status_follow_up", value: event?.target.checked}))} name="Follow up" />
              }
              label={t("followup.survey")}
            />
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item container xs={12} paddingY={3}>
        <IconButton onClick={handleAddPresence}>
          <AddCircleOutlineIcon color="primary" />
        </IconButton>
        <Grid item container xs={12} sm={5} alignItems='center' >
          <Typography> {t("presenceForm.title", "Presence Form")}</Typography>
        </Grid>

      </Grid>
      <Grid container spacing={3}>
        {presences.map((presence) => (
          <Grid item xs={12} key={presence.presence_id}>
            <Card sx={{padding: 2}}>
              <CardHeader
                title={`${t("presenceForm.presence")}`}
                action={
                  <IconButton
                    onClick={() => handleRemovePresence(presence.presence_id)}
                  >
                    <RemoveCircleOutlineIcon color="error" />
                  </IconButton>
                }
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("presenceForm.presenceFrom", "Presence From")}
                      value={presence.presence_from}
                      onChange={(event) =>
                        handleChangePresence(presence.presence_id, "presence_from", event.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label={t("presenceForm.presenceTo", "Presence To")}
                      value={presence.presence_to}
                      onChange={(event) =>
                        handleChangePresence(presence.presence_id, "presence_to", event.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={presence.is_expected}
                          onChange={(e) =>
                            handleChangePresence(presence.presence_id, "is_expected", e.target.checked)
                          }
                        />
                      }
                      label={presence.is_expected ? t("presenceForm.isExpected", "Is Expected") : t("presenceForm.isNotExpected", "Is Not Expected")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>{t("presenceForm.weekday", "weekday")}</InputLabel>
                      <Select
                        value={presence.weekday}
                        onChange={(e) =>
                          handleChangePresence(presence.presence_id, "weekday", e.target.value)
                        }
                      >
                        <MenuItem value={2}>{t("days.monday", "Monday")}</MenuItem>
                        <MenuItem value={3}>{t("days.tuesday", "Tuesday")}</MenuItem>
                        <MenuItem value={4}>{t("days.wednesday", "Wednesday")}</MenuItem>
                        <MenuItem value={5}>{t("days.thursday", "Thursday")}</MenuItem>
                        <MenuItem value={6}>{t("days.friday", "Friday")}</MenuItem>
                        <MenuItem value={7}>{t("days.saturday", "Saturday")}</MenuItem>
                        <MenuItem value={1}>{t("days.sunday", "Sunday")}</MenuItem>

                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Card>


  );
}
