import {
  Grid,
  Card,
  CardHeader,
  IconButton,
} from "@mui/material";
import {Link} from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import React from "react";
import {connect} from "react-redux";
import {routes} from "utils/routes";
import {useTranslation} from "react-i18next";
export const HomePage = () => {
  const {i18n} = useTranslation();
  return (<div>
    <AuthenticatedTemplate>

      <Grid container spacing={3} padding={1}>
        <Grid item container spacing={3} padding={3}>
          {routes(i18n)
              .filter((route)=>route.isHomeButton)
              .map((route)=>(
                <Grid item xs={4} key={route.label}>
                  <Card sx={{"display": "grid",
                    "width": "100%",
                    "height": "100%",
                    "textAlign": "center",
                    "&:focus, &:hover, &:visited, &:link, &:active": {
                      textDecoration: "none",
                      color: "black",
                    }}}component={Link} to={route.path} >
                    <CardHeader
                      avatar={<IconButton color="primary">{route.icon}</IconButton>}
                      title={route.label}
                    />
                  </Card>
                </Grid>
              ))}
        </Grid>
      </Grid>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
      <div
        cy-data="UnauthenticatedTemplateComponent"
        id="UnauthenticatedTemplateComponent">...</div>
    </UnauthenticatedTemplate>

  </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
