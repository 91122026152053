import {AlertColor} from "@mui/material";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface errorBoundaryFormState {
  openMessage:boolean,
  feedbackType:AlertColor,
  feedbackMessage:string
}

const initialState: errorBoundaryFormState = {
  openMessage: false,
  feedbackType: "info",
  feedbackMessage: "",
};


export const errorBoundaryFormSlice = createSlice({
  name: "ErrorBoundary",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setSnackbarStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      openMessage: action.payload,
    }),
    displayToastError: (state, {payload}:PayloadAction<string>)=>({
      ...state,
      openMessage: true,
      feedbackType: "error",
      feedbackMessage: payload,
    }),
    displayToastSuccess: (state, {payload}:PayloadAction<string>)=>({
      ...state,
      openMessage: true,
      feedbackType: "success",
      feedbackMessage: payload,
    }),
  },
});

export const {setSnackbarStatus, displayToastError, displayToastSuccess} = errorBoundaryFormSlice.actions;

export default errorBoundaryFormSlice.reducer;
