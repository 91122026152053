import React, {useState, SyntheticEvent} from "react";

import {
  AppBar,
  Button,
  Container,
  Menu,
  MenuItem,
  Grid,
} from "@mui/material";
import {
  Login,
  Logout,
  Language,
  AccountBox,
} from "utils/icons";
import {
  logout,
  login,
} from "utils/msalProvider";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import i18n from "utils/i18n";
import {routes} from "utils/routes";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

enum Visibility {
  Authenticated,
  Unauthenticated,
  Any
}

interface MenuItem {
  label: string;
  onClick: ()=>void;
}


export const Header = ()=>{
  const {i18n} = useTranslation();
  const links = routes(i18n).filter((e)=>e.linkInHeader);
  const menuButtons = {
    "language": [
      {
        label: i18n.t("header.language.de"),
        onClick: ()=> i18n.changeLanguage("de"),
      },
      {
        label: i18n.t("header.language.fr"),
        onClick: ()=> i18n.changeLanguage("fr"),
      },
    ],
  } as {[key: string]: MenuItem[]};
  const buttons=[
    {
      visibility: Visibility.Unauthenticated,
      id: "loginButton",
      getIcon: ()=><Login fontSize="small"/>,
      onClick: ()=>login(),
    },
    {
      visibility: Visibility.Authenticated,
      id: "logoutButton",
      getIcon: ()=><Logout fontSize="small"/>,
      onClick: ()=>logout(),
    },
    {
      visibility: Visibility.Any,
      id: "changeLanguageMenu",
      getIcon: ()=><Language fontSize="small"/>,
      onClick: (e:SyntheticEvent)=>openMenu(e.currentTarget, "language"),

    },
  ];
  const [menuOpen, setMenuOpen] = useState(Object.keys(menuButtons)
      .reduce((a, e)=>Object.assign(a, {[e]: false}), {} as {[key: string]: any}),
  );

  const [anchorAddEl, setAnchorAddEl] = React.useState(null);
  const openAddMenu = Boolean(anchorAddEl);
  const handleAddClick = (event) => {
    setAnchorAddEl(event.currentTarget);
  };
  const handleAddClose = () => {
    setAnchorAddEl(null);
  };

  const [anchorListEl, setAnchorListEl] = React.useState(null);
  const openListMenu = Boolean(anchorListEl);
  const handleListClick = (event) => {
    setAnchorListEl(event.currentTarget);
  };
  const handleListClose = () => {
    setAnchorListEl(null);
  };

  const openMenu=(e:EventTarget, key:string)=>{
    setMenuOpen({...menuOpen, [key]: e});
  };
  const buttonProps={
    color: "inherit" as "inherit",
    sx: {"minWidth": "0px"},
  };
  return (
    <AppBar position="static" color="inherit">
      <Container maxWidth="lg" >
        {/* <Toolbar variant="dense"> */}
        <Grid container justifyContent="space-between">
          <Grid item xs={6} alignSelf="center">
            <a href="/">
              <img src={require("./../assets/img/brand/logo.png")} style={{width: "160px"}} alt="" /><br />
            </a>
          </Grid>
          <Grid item container xs={6}>
            <Grid item xs={4}>
              <AuthenticatedTemplate>
                <Button onClick={handleAddClick}
                  sx={{color: "#2f353a"}}
                  endIcon={<KeyboardArrowDownIcon />}>
                  {`${i18n.t("add.Menu")}`}
                </Button>
                <Menu anchorEl={anchorAddEl}
                  open={openAddMenu}
                  onClose={handleAddClose}>
                  {links.filter((e)=>e.type==="new")
                      .map((e)=>(<MenuItem onClick={handleAddClose}
                        component={Link}
                        key={e.path}
                        to={e.path}>
                        {e.label}
                      </MenuItem>))}
                </Menu>
              </AuthenticatedTemplate>
            </Grid>
            <Grid item xs={4}>
              <AuthenticatedTemplate>
                <Button onClick={handleListClick}
                  sx={{color: "#2f353a"}}
                  endIcon={<KeyboardArrowDownIcon />}>
                  {`${i18n.t("list.Menu")}`}
                </Button>
                <Menu anchorEl={anchorListEl}
                  open={openListMenu}
                  onClose={handleListClose}>
                  {links.filter((e)=>e.type==="list")
                      .map((e)=>(<MenuItem onClick={handleListClose}
                        component={Link}
                        key={e.path}
                        to={e.path}>
                        {e.label}
                      </MenuItem>))}
                </Menu>
              </AuthenticatedTemplate>
            </Grid>
            <Grid item container xs={4} alignSelf="center" justifyContent='space-evenly'>
              <AuthenticatedTemplate>
                {
                  buttons
                      .filter((e)=>e.visibility===Visibility.Authenticated)
                      .map((e)=>(<Button
                        {...buttonProps}
                        onClick={e.onClick}
                        data-cy={e.id}
                        id={e.id}
                        key={e.id}>{e.getIcon()}</Button>))
                }
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                {
                  buttons
                      .filter((e)=>e.visibility===Visibility.Unauthenticated)
                      .map((e)=>(<Button
                        {...buttonProps}
                        onClick={e.onClick}
                        data-cy={e.id}
                        id={e.id}
                        key={e.id}>{e.getIcon()}</Button>))
                }
              </UnauthenticatedTemplate>
              {
                buttons
                    .filter((e)=>e.visibility===Visibility.Any)
                    .map((e)=>(<Button
                      {...buttonProps}
                      onClick={e.onClick}
                      data-cy={e.id}
                      id={e.id}
                      key={e.id}>{e.getIcon()}</Button>))
              }
            </Grid>
          </Grid>
        </Grid>

        {/* </Toolbar> */}
      </Container>
      {Object.keys(menuButtons).map((key)=>(
        <Menu
          key={key}
          open={Boolean(menuOpen[key])}
          anchorEl={menuOpen[key]}
          onClose={()=>setMenuOpen({...menuOpen, [key]: false})}
          onClick={()=>setMenuOpen({...menuOpen, [key]: false})}
          disableAutoFocusItem
          disableScrollLock>
          {menuButtons[key].map((menuButton:MenuItem)=>(
            <MenuItem
              key={menuButton.label}
              onClick={menuButton.onClick}
            >
              {menuButton.label}
            </MenuItem>),
          )}
        </Menu>
      ))}
    </AppBar>
  );
};
